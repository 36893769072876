<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      Team: "Time",
      Dashboard: "Painel",
      Package: "Pacote",
      Graduation: "Graduação",
      Points: "Pontos",
      "Referral link": "Link de indicação",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Pontos",
    },
    es: {
      Team: "Equipo",
      Dashboard: "Panel",
      Package: "Paquete",
      Graduation: "Graduación",
      Points: "Puntos",
      "Referral link": "Link de patrocineo",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Puntos",
    },
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      total: {
        quotas: "0",
        bonus: "$-.--",
        balance: "$-.--",
      },

      monthly: "inactive",

      graduation: {
        id: null,
        name: "-",
        image: null,
      },

      statData: null,
      wallets: null,

      notifications: true,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null,
        },
        subtotal: "$-.--",
        shipping: {
          method: "-",
          value: "-",
        },
        total: "$-.--",
      },
    };
  },
  methods: {
    getQuotas() {
      api.get("quotas/total").then((response) => {
        if (response.data.status == "success") {
          this.total.quotas = response.data.total;
          this.getStatData();
        }
      });
    },
    getGraduation() {
      api.get("report/graduation").then((response) => {
        if (response.data.status == "success") {
          this.graduation.id = response.data.current.id;
          this.graduation.name = response.data.current.name;
          this.graduation.image = response.data.current.image;
        }
      });
    },
    getMonthly() {
      api.get("report/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getTotalBonus() {
      api.get("report/bonus/total").then((response) => {
        if (response.data.status == "success") {
          this.total.bonus = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          type: "center",
          icon: "quotas",
          title: "Minhas Cotas",
          value: this.total.quotas.toString(),
        },
      ];
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api.get("user/notifications").then((response) => {
          if (response.data.status === "success") {
            if (response.data.devices == "active") {
              this.notifications = true;
            } else {
              this.notifications = false;
            }
          } else {
            this.notifications = false;
          }
        });
      }
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get("store/cart")
          .then((response) => {
            if (response.data.status == "success") {
              this.setStorage(response.data);
            }
          })
          .catch((error) => {
            this.cart.errored = error;
          });
      } else {
        this.cart = JSON.parse(localStorage.cart);
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total;
      this.cart.items.list = data.items.list;
      this.cart.subtotal = data.subtotal;
      this.cart.shipping = data.shipping;
      this.cart.total = data.total;

      localStorage.cart = JSON.stringify(this.cart);
    },
  },
  mounted() {
    this.getQuotas();
    this.getTotalBonus();
    this.getStatData();
    this.checkNotifications();
    this.getCart();
  },
};
</script>

<template>
  <Layout>
    <router-link
      v-if="!notifications"
      to="/account/notifications"
      class="d-none text-dark"
    >
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>
              Fique informado de todas as atualizações do seu escritório
              virtual, ative suas notificações Web Push!
            </div>
          </div>
        </div>
      </div>
    </router-link>

    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img
              class="rounded-circle header-profile-user m-2 mx-2"
              :src="cart.items.list[Object.keys(cart.items.list)[0]].image"
              width="30px"
              height="30px"
            />
            <div class="py-3">
              Você deixou alguns produtos em seu carrinho, clique aqui para
              finalizar o seu pedido!
            </div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="row justify-content-center mb-5">
      <div class="col-lg-8">
        <div class="row mt-3">
          <div class="col-md-12 mb-3 align-self-center text-center">
            <router-link
              to="tokens/buy"
              class="btn btn-success btn-block btn-lg btn-rounded"
            >
              Comprar Tokens</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 order-md-12 text-center">
            <img
              class="img-fluid rounded-circle"
              src="@/assets/images/pages/photo-1.jpeg"
              style="max-height: 300px"
            />
          </div>
          <div class="col-md-6 mb-3 order-md-1 align-self-center text-md-right">
            <h2 class="text-default"><strong>A-SHOW</strong></h2>
            <h5>
              Uma empresa que veio para revolucionar o mercado de shows e
              entretenimentos no Brasil e no mundo. Nossa missão é fazer uma
              fusão entre o mercado de shows tradicionais e o mercado de
              criptoativos, descomplicando e trazendo muitos lucros para você,
              dando a você muito mais que bons momentos e diversão, vamos te dar
              riqueza.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 order-md-1 text-center">
            <img
              class="img-fluid rounded-circle"
              src="@/assets/images/pages/photo-2.jpeg"
              style="max-height: 300px"
            />
          </div>
          <div class="col-md-6 mb-3 order-md-12 align-self-center">
            <h2 class="text-default"><strong>Seja um A-SHOW VIP!</strong></h2>
            <h5>
              Com o A-SHOW VIP você é vip de verdade. Primeiro sistema de cotas
              que te possibilita ter lucros com os melhores e maiores shows do
              Brasil e do mundo, com um investimento acessível!! É isso mesmo.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 order-md-12 text-center">
            <img
              class="img-fluid rounded-circle"
              src="@/assets/images/pages/photo-3.jpeg"
              style="max-height: 300px"
            />
          </div>
          <div class="col-md-6 mb-3 order-md-1 align-self-center text-md-right">
            <h2 class="text-default"><strong>Cotas</strong></h2>
            <h5>
              Serão apenas 400 cotas A-SHOW VIP, e serão disponibilizadas
              somente nessa curta private. você literalmente é privilegiado por
              estar fazendo parte disso tudo, muito antes de milhares de
              pessoas.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 order-md-1 text-center">
            <img
              class="img-fluid rounded-circle"
              src="@/assets/images/pages/photo-4.jpeg"
              style="max-height: 300px"
            />
          </div>
          <div class="col-md-6 mb-3 order-md-12 align-self-center">
            <h2 class="text-default"><strong>A-SHOW VIP!</strong></h2>
            <h5>
              Após as vendas das cotas A-SHOW VIP, iremos iniciar nossa
              pré-venda de tokens. Acredite você irá participar de algo jamais
              visto no mercado de criptoativos!
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 order-md-12 text-center">
            <img
              class="img-fluid rounded-circle"
              src="@/assets/images/pages/photo-5.jpeg"
              style="max-height: 300px"
            />
          </div>
          <div class="col-md-6 mb-3 order-md-1 align-self-center text-md-right">
            <h2 class="text-default"><strong>Pré-venda</strong></h2>
            <h5>
              Assim que finalizarmos a venda das cotas A-SHOWVIP, vamos anunciar
              o início da pré-venda dos tokens, e já iremos apresentar a vocês e
              a todos os futuros investidores o nosso primeiro show com artista
              contratado, local, data do evento, cidade, estado e etc.<br /><br />
              Resumindo, o projeto já nasce gigante e nosso lançamento vai ser
              comemorado com nosso primeiro evento 100% realizado pela A-SHOW, e
              você já começa a lucrar. Ou seja, você não precisa esperar anos
              para que algo seja construído ou vendido para que seus
              investimentos valorizem, a A-SHOW faz isso para você em tempo
              recorde!
            </h5>
          </div>
        </div>
        <div class="row justify-content-center mt-4 text-center">
          <div class="col-md-8 mb-3">
            <h2 class="text-default mb-4">
              <strong>Distribuição das Moedas ASHOW</strong>
            </h2>
            <h5></h5>
            <table class="table">
              <thead>
                <tr>
                  <th>Alocação</th>
                  <th>Tokens</th>
                  <th>Distribuição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Supply</td>
                  <td>20.000.000</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>Pré Venda</td>
                  <td>1.000.000</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Liquidez</td>
                  <td>1.000.000</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Marketing</td>
                  <td>2.000.000</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>Devs</td>
                  <td>4.000.000</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Fundos Projeto</td>
                  <td>12.000.000</td>
                  <td>60%</td>
                </tr>
              </tbody>
            </table>
            <p>Obs: tokens dos devs estão devidamentes bloqueados por 1 ano.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-4 col-lg-4 col-xl-4"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div class="row d-none">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t("Referral link") }}
            </h5>
            <p class="m-0 notranslate">
              https://vip.ashows.com.br/{{ account.user.username }}
              <button
                class="btn btn-link p-0 ml-2"
                v-clipboard:copy="
                  'https://vip.ashows.com.br/' + account.user.username
                "
              >
                <i class="bx bx-copy font-size-18 text-dark"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
td {
  border-top: 1px solid #eff2f7 !important;
}
</style>
